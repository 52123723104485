<template>
    <q-layout>
        <!-- TOP / BRAND -->
        <!-- navbar -->
        <navbar></navbar>

        <!----------------------->
        <!-- PAGE SUB HEADER -->
        <div class="bg-mineralgreen">
            <div class="container mx-auto">
                <div class="p-5 row">
                    <div class="col-12 flex flex-col items-start">
                        <h1 class="text-white text-xl font-black font-epigrafica mt-2 uppercase">Nos Ingrédients Naturels</h1>
                        <p class="text-white">Pour nous, des cosmétiques 100% naturels signifie 100% de pureté. <br> Nos ingrédients fonctionnent. Ils sont remplis de vitamines naturelles</p>
                    </div>
                </div>
            </div>
        </div>

        <!-- ABOUT -->
        <div class="white">
            <div class="container mx-auto">
                <div class="py-14 bg-cover row">
                    <div class="container mx-auto">
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-4 p-5 img-smooth-zoom">
                                <div class="relative">
                                    <div class="relative mb-20">
                                        <div class="absolute z-40 bg-white m-10 p-4 -bottom-24 border-solid border-pastelgolden border-b-2">
                                            <h1 class="text-mineralgreen text-lg font-black font-epigrafica uppercase">Beurre de Karité</h1>
                                            <p class="text-mineralgreen mb-2">Hydrate et nourrit en profondeur la peau, mais aussi les fibres capillaires...</p>
                                            <a href="/page/ingredient/1/karite" class="text-mineralgreen-dark hover:text-mineralgreen font-bold">Découvrir &rarr;</a>
                                        </div>
                                        <q-img class="" width="100%" src="@/assets/media/ingredients/ibc-international-bio-cosmetics-karite.jpg" />
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 col-sm-12 col-md-4 p-5 img-smooth-zoom">
                                <div class="relative">
                                    <div class="relative mb-20">
                                        <div class="absolute z-40 bg-white m-10 p-4 -bottom-24 border-solid border-pastelgolden border-b-2">
                                            <h1 class="text-mineralgreen text-lg font-black font-epigrafica uppercase">Le Miel</h1>
                                            <p class="text-mineralgreen mb-2">Absorbe et retient l'hydratation et laisse votre peau fraîche et souple...</p>
                                            <a href="/page/ingredient/2/miel" class="text-mineralgreen-dark hover:text-mineralgreen font-bold">Découvrir &rarr;</a>
                                        </div>
                                        <q-img width="100%" src="@/assets/media/ingredients/ibc-international-bio-cosmetics-miel.jpg" />
                                    </div>
                                </div>
                            </div>
                            
                            <div class="col-12 col-sm-12 col-md-4 p-5 img-smooth-zoom">
                                <div class="relative">
                                    <div class="relative mb-20">
                                        <div class="absolute z-40 bg-white m-10 p-4 -bottom-24 border-solid border-pastelgolden border-b-2">
                                            <h1 class="text-mineralgreen text-lg font-black font-epigrafica uppercase">L'Avocat</h1>
                                            <p class="text-mineralgreen mb-2">Un fruit bon pour l’organisme et aussi excellent pour l’épiderme et la fibre capillaire...</p>
                                            <a href="/page/ingredient/3/avocat" class="text-mineralgreen-dark hover:text-mineralgreen font-bold">Découvrir &rarr;</a>
                                        </div>
                                        <q-img width="100%" src="@/assets/media/ingredients/ibc-international-bio-cosmetics-avocat.jpg" />
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 col-sm-12 col-md-4 p-5 img-smooth-zoom">
                                <div class="relative">
                                    <div class="relative mb-20">
                                        <div class="absolute z-40 bg-white m-10 p-4 -bottom-24 border-solid border-pastelgolden border-b-2">
                                            <h1 class="text-mineralgreen text-lg font-black font-epigrafica uppercase">L'Aloe Vera</h1>
                                            <p class="text-mineralgreen mb-2">Actif apaisant idéal pour la peau. Il est un puissant cicatrisant...</p>
                                            <a href="/page/ingredient/4/aloe-vera" class="text-mineralgreen-dark hover:text-mineralgreen font-bold">Découvrir &rarr;</a>
                                        </div>
                                        <q-img width="100%" src="@/assets/media/ingredients/ibc-international-bio-cosmetics-aloe-vera.jpg" />
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 col-sm-12 col-md-4 p-5 img-smooth-zoom">
                                <div class="relative">
                                    <div class="relative mb-20">
                                        <div class="absolute z-40 bg-white m-10 p-4 -bottom-24 border-solid border-pastelgolden border-b-2">
                                            <h1 class="text-mineralgreen text-lg font-black font-epigrafica uppercase">Le Curcuma</h1>
                                            <p class="text-mineralgreen mb-2">Il a plusiseurs application : anti-inflammatoire, anti-âge et booster de collagène...</p>
                                            <a href="/page/ingredient/5/curcuma" class="text-mineralgreen-dark hover:text-mineralgreen font-bold">Découvrir &rarr;</a>
                                        </div>
                                        <q-img width="100%" src="@/assets/media/ingredients/ibc-international-bio-cosmetics-curcuma.jpg" />
                                    </div>
                                </div>
                            </div>
                            
                            <div class="col-12 col-sm-12 col-md-4 p-5 img-smooth-zoom">
                                <div class="relative">
                                    <div class="relative mb-20">
                                        <div class="absolute z-40 bg-white m-10 p-4 -bottom-24 border-solid border-pastelgolden border-b-2">
                                            <h1 class="text-mineralgreen text-lg font-black font-epigrafica uppercase">Le Concombre</h1>
                                            <p class="text-mineralgreen mb-2">Le concombre a des propriétés hydratantes, cicatrisantes, assainissantes et astringentes...</p>
                                            <a href="/page/ingredient/6/concombre" class="text-mineralgreen-dark hover:text-mineralgreen font-bold">Découvrir &rarr;</a>
                                        </div>
                                        <q-img width="100%" src="@/assets/media/ingredients/ibc-international-bio-cosmetics-concombre.jpg" />
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 col-sm-12 col-md-4 p-5 img-smooth-zoom">
                                <div class="relative">
                                    <div class="relative mb-20">
                                        <div class="absolute z-40 bg-white m-10 p-4 -bottom-24 border-solid border-pastelgolden border-b-2">
                                            <h1 class="text-mineralgreen text-lg font-black font-epigrafica uppercase">La Noix de Coco</h1>
                                            <p class="text-mineralgreen mb-2">Connue pour être un excellent soin cheveux et aussi riche en acides gras...</p>
                                            <a href="/page/ingredient/7/coco" class="text-mineralgreen-dark hover:text-mineralgreen font-bold">Découvrir &rarr;</a>
                                        </div>
                                        <q-img width="100%" src="@/assets/media/ingredients/ibc-international-bio-cosmetics-coco.jpg" />
                                    </div>
                                </div>
                            </div>
                            
                            <div class="col-12 col-sm-12 col-md-4 p-5 img-smooth-zoom">
                                <div class="relative">
                                    <div class="relative mb-20">
                                        <div class="absolute z-40 bg-white m-10 p-4 -bottom-24 border-solid border-pastelgolden border-b-2">
                                            <h1 class="text-mineralgreen text-lg font-black font-epigrafica uppercase">Le Beurre de Cacao</h1>
                                            <p class="text-mineralgreen mb-2">Il est hydratant, antioxydant et cicatrisant. Idéal pour les cheveux...</p>
                                            <a href="/page/ingredient/8/beurre-de-cacao" class="text-mineralgreen-dark hover:text-mineralgreen font-bold">Découvrir &rarr;</a>
                                        </div>
                                        <q-img width="100%" src="@/assets/media/ingredients/ibc-international-bio-cosmetics-beurre-de-cacao.jpg" />
                                    </div>
                                </div>
                            </div>
                            
                            <div class="col-12 col-sm-12 col-md-4 p-5 img-smooth-zoom">
                                <div class="relative">
                                    <div class="relative mb-20">
                                        <div class="absolute z-40 bg-white m-10 p-4 -bottom-24 border-solid border-pastelgolden border-b-2">
                                            <h1 class="text-mineralgreen text-lg font-black font-epigrafica uppercase">L'Huile de Sésame</h1>
                                            <p class="text-mineralgreen mb-2">L’huile de sésame est riche en vitamine E et en vitamine K1...</p>
                                            <a href="/page/ingredient/9/huile-de-sesame" class="text-mineralgreen-dark hover:text-mineralgreen font-bold">Découvrir &rarr;</a>
                                        </div>
                                        <q-img width="100%" src="@/assets/media/ingredients/ibc-international-bio-cosmetics-huile-de-sesame.jpg" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- FOOTER CONTENT -->
        <!-- footer -->
        <footerbar></footerbar>
        
    </q-layout>
</template>

<script>
import navbar from "@/views/shared/Navbar"
import footerbar from "@/views/shared/Footer"
import { useMeta } from "quasar"

export default {
    name: "Ingredients",
    components: { navbar, footerbar },
    data() {
        return {}
    },
    created () {
        useMeta({
            title: 'International Bio Cosmetics | Nos Ingrédients Naturels', // sets document title
        })
    }
};
</script>